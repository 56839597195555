import { Injectable } from '@angular/core';
import { ISharedSidebarItem } from '../../../shared/layouts/responsive-nav-bar/shared-sidebar-list/shared-sidebar.interface';
import { getFamilyPortalNavbarConfig } from '../../../family-portal/src/app/constants/nav-bar.config';

@Injectable()
export class NavbarConfigService {
  // can extend this to accept other configs in the future, right now, it is only for family portal
  public getNavConfigs (options: { [key: string]: any } = {}): { [key:string]: ISharedSidebarItem[] } {
    const { translatedContent } = options;
    return getFamilyPortalNavbarConfig(translatedContent);
  }
}
