import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nv-pill-v4',
  styleUrls: ['./nv-pill-v4.component.scss'],
  templateUrl: './nv-pill-v4.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NvPillV4Component implements OnChanges {
  @Input() color: string = 'blue';
  @Input() icon: string;
  @Input() iconPosition: 'left' | 'right' = 'right';
  @Input() prependIcon?: string;
  @Input() ariaLabelData?: {
    iconAriaLabel?: string;
    prependIconAriaLabel?: string;
  } = {
      iconAriaLabel: null,
      prependIconAriaLabel: null,
    };

  @Input() isDisabled: boolean = false;
  @Input() text: string;
  @Input() isActive: boolean = false;

  public pillClass: string = '';

  ngOnChanges (_: SimpleChanges): void {
    const tmpClass: string[] = [];
    tmpClass.push(this.color.toLowerCase());
    if (this.isDisabled) tmpClass.push('pill-disabled');
    if (this.iconPosition) tmpClass.push(`icon-${this.iconPosition}`);
    if (this.isActive) tmpClass.push('active');
    this.pillClass = tmpClass.join(' ');
  }
}
