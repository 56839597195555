import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nv-aria-label',
  templateUrl: './nv-aria-label.component.html',
  styleUrls: ['./nv-aria-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvAriaLabelComponent {
  public ariaLabelText: string;
  public ariaLabelId: string;
}
