import { WindowRef } from '../../../../projects/shared/services/window-ref/windowRef';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'family-header',
  templateUrl: './family-header.component.html',
  styleUrls: ['./family-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FamilyHeaderComponent {
  @Input() public headerImage: { src: string; alt: string; } = {
    src: 'https://www.nyc.gov/assets/nyc4d/images/pages/intergration/nyc_white@x2.png',
    alt: 'white NYC logo',
  };

  @Input() public applicationName: string = 'Family Portal: Every Child and Family is Known';

  @Input() public profileLabel: string;
  @Input() public hideProfileBtn: boolean = false;

  @Input() public logOutLabel: string;
  @Input() public hideLogoutBtn: boolean = false;

  @Input() private nycIdProfileUrl: string;

  @Output() private onLogOut = new EventEmitter<void>();

  @ContentChild('languageSwitcher') languageSwitcher: TemplateRef<any>;

  constructor (
    private windowRef: WindowRef,
  ) {}

  goToNycWebsite (): void {
    const window = this.windowRef.nativeWindow;
    window.open('http://nyc.gov', '_self');
  }

  goToProfile (): void {
    if (this.nycIdProfileUrl) {
      const window = this.windowRef.nativeWindow;
      window.open(this.nycIdProfileUrl, '_self');
    }
  }

  logOut (): void {
    this.onLogOut.emit();
  }
}
