import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../services/api/api-service/api-service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { of } from 'rxjs';

@Injectable()
export class HomeGuard {
  constructor (private router: Router, private apiService: ApiService, private authService: AuthService) { }

  canActivate () {
    return this.apiService.getCurrentUser() // must getch fresh user to account for consent changing in real time during embedded signing view
      .pipe(
        map(({ data: { currentUser: { hasFamilyPortalAccess } } }) => {
          const urlForMissingConsent = this.router.createUrlTree(['/consent']);
          return hasFamilyPortalAccess ? true : urlForMissingConsent;
        }),
        catchError((err) => {
          console.log('Error getting current user or family:', err);
          this.authService.logout({
            logoutParams: {
              federated: true,
              returnTo: window.location.origin,
            },
          });
          return of(false);
        }),
      );
  }
};
