<!-- responsive layout -> responsive nav -> config - toggle between side nav and top nav -->

<skip-link [links]="links" (skipLinkClick)="skipFocusTo($event)"></skip-link>

<div class="canvas">
  <header><ng-content select="[header]"></ng-content></header>
  <div
    [ngClass]="{ 
    'side-nav-layout': navType === 'SIDE_NAV' && !hideNav,
    'top-nav-layout': navType === 'TOP_NAV_CLOSED' && !hideNav || navType === 'TOP_NAV_OPENED' && !hideNav,
    'top-nav-closed': navType === 'TOP_NAV_CLOSED' && !hideNav,
    'top-nav-opened': navType === 'TOP_NAV_OPENED',
    'nav-hidden': hideNav
  }"
  >
    <nav-bar
      *ngIf="!hideNav"
      role="navigation"
      class="nav-bar"
      [navType]="navType"
      [translatedNavBarConfig$]="translatedNavBarConfig$"
      [translatedNavBarAccessibility$]="translatedNavBarAccessibility$"
      (clickedBurgerMenu)="changeNavType($event)"
    >
      <footer *ngIf="navType.includes('TOP_NAV')" #footer tabindex="0" navFooter><ng-content select="[innerFooter]"></ng-content></footer>
    </nav-bar>
    <div role="main" #mainContent class="outlet-wrapper" tabindex="0"><router-outlet></router-outlet></div>
    <footer *ngIf="navType === 'SIDE_NAV' || navType === 'TOP_NAV_CLOSED'" #footer tabindex="0"><ng-content select="[footer]"></ng-content></footer>
  </div>
</div>
