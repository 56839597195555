<button
  matRipple
  [ngClass]="isIconInverted ? 'mat-ripple-inverted' : ''"
  [disabled]="disabled"
  [type]="htmlType"
  (click)="handleClick($event)"
  [type]="htmlType"
  (mousedown)="onMouseDown($event)"
>
  <nv-icon
    *ngIf="shouldShowIcon && iconLocation === 'before'"
    [name]="icon"
    [isInverted]="isIconInverted"
    nvAriaLabel
    [ariaLabelText]="ariaLabelData.beforeIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
  <ng-content></ng-content>
  <nv-icon
    *ngIf="shouldShowIcon && iconLocation === 'after'"
    [name]="icon"
    [isInverted]="isIconInverted"
    nvAriaLabel
    [ariaLabelText]="ariaLabelData.afterIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
</button>
