import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { debounce } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ILinkAccessibilityContent } from '../../components/skip-link/skip-link.interface';
import { INavBarAccessibilityContent, INavBarConfigContent } from '../responsive-nav-bar/nav-bar.interface';
import { ResponsiveLayoutService } from './responsive-layout-service';

@Component({
  selector: 'responsive-layout',
  templateUrl: './responsive-layout.component.html',
  styleUrls: ['./responsive-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResponsiveLayoutComponent implements OnInit {
  @Input() translatedLinkAccessibility$?: Observable<ILinkAccessibilityContent>;

  @Input() translatedNavBarAccessibility$?: Observable<INavBarAccessibilityContent>;

  @Input() translatedNavBarConfig$?: Observable<INavBarConfigContent>;

  @Input() hideNav: boolean = false;

  private destroy$ = new Subject<void>();

  constructor (
    public elementRef: ElementRef<HTMLElement>,
    private resLayoutService: ResponsiveLayoutService,
  ) {}

  public links = [
    { target: 'mainContent', label: 'Skip to main content' },
    { target: 'footer', label: 'Skip to footer' },
  ];

  ngOnInit () {
    this.translatedLinkAccessibility$?.pipe(
      tap((content) => {
        this.links = [
          { target: 'mainContent', label: content.skipToMainContent },
          { target: 'footer', label: content.skipToFooter },
        ];
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  @ViewChild('mainContent', { static: false }) mainContentElement: ElementRef;
  @ViewChild('footer', { static: false }) footerElement: ElementRef;
  public navType: 'SIDE_NAV' | 'TOP_NAV_CLOSED' | 'TOP_NAV_OPENED' = 'SIDE_NAV';

  ngAfterViewInit () {
    this.onResize({ target: this.elementRef.nativeElement });
  }

  ngOnDestroy () {
    this.destroy$.next();
  };

  // TODO - tech debt - leverage isMobile service
  @HostListener('window:resize', ['$event'])
    onResize = debounce($event => {
      const isSmallViewport = this.resLayoutService.isSmallViewportWidth($event.target);
      if (isSmallViewport) {
        this.navType = 'TOP_NAV_CLOSED';
      } else {
        this.navType = 'SIDE_NAV';
      }
    }, 20);

  public changeNavType ($event: { action: 'closeTopNavMenu' | 'openTopNavMenu' }) {
    const { action } = $event;
    if (action === 'openTopNavMenu') {
      this.navType = 'TOP_NAV_OPENED';
    }
    if (action === 'closeTopNavMenu') {
      this.navType = 'TOP_NAV_CLOSED';
    }
  }

  public skipFocusTo (target: string): void {
    switch (target) {
      case 'mainContent':
        this.skipFocusToMainContent();
        break;
      case 'footer':
        this.skipFocusToFooter();
        break;
      default:
        break;
    }
  }

  public skipFocusToMainContent (): void {
    const mainContent = this.mainContentElement.nativeElement;
    if (mainContent) {
      mainContent.focus();
    }
  }

  public skipFocusToFooter (): void {
    const footer = this.footerElement.nativeElement;
    if (footer) {
      footer.focus();
    }
  }
}
