
import { Injectable } from '@angular/core';
import { WindowRef } from '../window-ref/windowRef';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class GoogleAnalyticsTrackerService {
  constructor (
    private windowRef: WindowRef,
    private router: Router,
  ) {}

  public handleRouteEvents (): void {
    // needed for sending every route change to google analytics
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        const previousPagePath = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString() || '(entrance)';
        this.windowRef.nativeWindow.gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
          previous_page_path: previousPagePath,
          page_location: event.urlAfterRedirects,
          page_referrer: previousPagePath,
        });
      }),
    ).subscribe();
  }

  public setUserAnalyticsHash (hash: string): void {
    (this.windowRef.nativeWindow).gtag('set', {
      user_id: hash,
      user_properties: {
        user_analytics_hash: hash,
      },
    });
  }
}
