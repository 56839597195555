const loadDocusignJs = async (environment) => {
  const script = document.createElement('script');

  let url = 'https://js-d.docusign.com/bundle.js'; // default to dev bundle
  if (environment?.production) {
    url = 'https://js.docusign.com/bundle.js';
  }

  script.src = url;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;

  await addScriptTagToDocument(script);
};

const addScriptTagToDocument = (script: HTMLScriptElement): Promise<true | Error> => {
  return new Promise((resolve, reject) => {
    script.onload = () => {
      console.log(`Script loaded: ${script.src}`);
      resolve(true);
    };

    script.onerror = (error: any) => {
      console.error(`Error loading script: ${script.src}`, error);
      reject(error);
    };

    document.head.appendChild(script);
  });
};

const loadVendorBundles = async (environment) => {
  try {
    // load other bundles here if needed
    await loadDocusignJs(environment);
  } catch (error) {
    console.log(`Error loading vendor bundles: ${error.message}`);
  }
};

// Do not export scripts to load library specific bundles - load them here in loadVendorBundles.
export default loadVendorBundles;
