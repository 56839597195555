import { FamilyPortalLayoutPageComponent } from './../../../components/family-portal-layout-page/family-portal-layout-page.component';
import { Route } from '@angular/router';
import { authGuard } from '../../guards/auth-guard/auth-guard';
import { HomeGuard } from '../../guards/home-guard/home-guard';

export const homeRoute: Route = {
  path: 'home',
  component: FamilyPortalLayoutPageComponent,
  loadChildren: () => import('../../../components/home/home.module').then(m => m.HomeModule),
  canActivate: [authGuard, HomeGuard],
  title: 'Home',
};
