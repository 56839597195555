import { AuthService } from '@auth0/auth0-angular';
import { CanActivateFn, Router } from '@angular/router';
import { CurrentUserService } from '../../../services/current-user/current-user.service';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { UserInactivity } from '../../../services/user-inactivity/user-inactivity.service';
import { FamilyPortalConfig } from '../../../services/family-portal-config';

export const authGuard: CanActivateFn = () => {
  const currentUserService = inject(CurrentUserService);
  const authService = inject(AuthService);
  const router = inject(Router);
  const userInactivity = inject(UserInactivity);
  const familyPortalConfig = inject(FamilyPortalConfig);
  const { LANDING_PAGE_URL } = familyPortalConfig.publicConfig;

  return authService.isAuthenticated$.pipe(
    switchMap(isAuthenticated => {
      if (isAuthenticated) {
        // if the user is authenticated, check if they are authorized
        return currentUserService.getCurrentUser$().pipe(
          mapTo(true),
          tap(() => {
            // start the user session inactivity timer
            userInactivity.start();
          }),
          catchError((err) => {
            console.log('Error getting current user:', err);
            // if the user is not authorized, log them out
            const isUnauthorized = err.status === 401;
            const returnTo = isUnauthorized ? `${window.location.origin}/not-found?type=unauthorized` : window.location.origin;
            authService.logout({
              logoutParams: {
                federated: true,
                returnTo,
              },
            });
            return of(false);
          }),
        );
      } else {
        if (LANDING_PAGE_URL) {
          // if there is a landing page for the environment, redirect the user to it.
          // landing page will redirect to `/login` when user clicks on login button
          window.location.href = LANDING_PAGE_URL;
          return of(false);
        } else {
          return of(router.createUrlTree(['login']));
        }
      }
    }),
  );
};
