import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvButtonComponent } from './nv-button.component';
import { NvAriaLabelModule } from '../directives/nv-aria-label/nv-aria-label.module';

@NgModule({
  imports: [CommonModule, MatRippleModule, NvIconModule, NvAriaLabelModule],
  declarations: [NvButtonComponent],
  exports: [NvButtonComponent],
})
export class NvButtonModule {}
