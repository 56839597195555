import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvPillV4Module } from '../nv-pill-v4/nv-pill-v4.module';
import { NvPillButtonV4Component } from './nv-pill-button-v4.component';
import { NvAriaLabelModule } from '../directives/nv-aria-label/nv-aria-label.module';

@NgModule({
  declarations: [NvPillButtonV4Component],
  imports: [CommonModule, MatRippleModule, NvIconModule, NvPillV4Module, NvAriaLabelModule],
  exports: [NvPillButtonV4Component],
})
export class NvPillButtonV4Module {}
