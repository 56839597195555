import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { NvDropdownItemModule } from '../../../nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { SharedSidebarItemModule } from '../shared-sidebar-item/shared-sidebar-item.module';
import { SharedSidebarListResponsiveComponent } from './shared-sidebar-list-responsive.component';
import { SharedSidebarListModule } from '../shared-sidebar-list/shared-sidebar-list.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    MatTreeModule,
    MatMenuModule,
    NvDropdownItemModule,
    SharedSidebarItemModule,
    SharedSidebarListModule,
    A11yModule,
  ],
  declarations: [SharedSidebarListResponsiveComponent],
  exports: [SharedSidebarListResponsiveComponent],
})
export class SharedSidebarListResponsiveModule {}
