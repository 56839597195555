<div [ngClass]="pillClass">
  <nv-icon
    *ngIf="prependIcon"
    [name]="prependIcon"
    nvAriaLabel
    [ariaLabelText]="ariaLabelData.prependIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
  <ng-container *ngIf="text; else elseTemplate">
    <span>{{ text }}</span>
  </ng-container>
  <ng-template #elseTemplate
    ><span #textElement><ng-content></ng-content></span
  ></ng-template>
  <nv-icon
    *ngIf="icon"
    [name]="icon"
    [color]="color"
    [isInverted]="!isInverted"
    nvAriaLabel
    [ariaLabelText]="ariaLabelData.iconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
</div>
