import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { NvDropdownItemModule } from '../../../nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { SharedSidebarItemModule } from '../shared-sidebar-item/shared-sidebar-item.module';
import { SharedSidebarListHeaderComponent } from './shared-sidebar-list-header/shared-sidebar-list-header.component';
import { SharedSidebarListComponent } from './shared-sidebar-list.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [CommonModule, SharedSidebarItemModule, MatTreeModule, MatMenuModule, NvDropdownItemModule, A11yModule],
  declarations: [SharedSidebarListComponent, SharedSidebarListHeaderComponent],
  exports: [SharedSidebarListComponent],
})
export class SharedSidebarListModule {}
