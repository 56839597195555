import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvPillV4Component } from './nv-pill-v4.component';
import { NvAriaLabelModule } from '../directives/nv-aria-label/nv-aria-label.module';

@NgModule({
  declarations: [NvPillV4Component],
  exports: [NvPillV4Component],
  imports: [CommonModule, NvIconModule, NvAriaLabelModule],
})
export class NvPillV4Module {}
