import { Injectable } from '@angular/core';

@Injectable()
/**
 * This class gets entended by other classes, if anything is added in the constructor,
 * make sure to properly call super in the child class
 */
export class SharedUtilitiesService {
  // generate UUID
  createV4Uuid () {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return uuid;
  }
}
