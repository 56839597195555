import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateHelpers {
  // `get moment()` makes unit testing easier
  // To spy/overwrite moment, use spyOnProperty(DateHelpers, 'moment', 'get') (CM).
  get moment() {
    return moment;
  }

  // new Date does not work in non-Chrome browsers for dates formated "MMM YYYY" e.g "June 2018", so
  // we are using moment in this case - C.M.
  // two places where this applies - plannedGraduationDate and regents adminDate(s)
  getMomentObjForMonthYearDate(string) {
    return this.moment(string, 'MMM YYYY');
  }

  getMomentObjForMonthDate(string) {
    return this.moment(string).format('MMM DD');
  }

  getMomentObjForMonthDateShort (string: string): string {
    return this.moment(string).format('MMM D');
  }

  // `format` - format of date that moment can interpret
  getMomentObj(date?: string, format?: string) {
    return date ? this.moment(date, format) : this.moment();
  }

  // returns a span of variable unit (minute, hour, day, week, year)
  // including the amount and the unit (JE)
  getMomentSinceVariableUnit(date: string) {
    const result = { count: 0, unit: '' };
    const now = this.moment();
    const dateMoment = this.moment(date);
    const diffms = now.diff(dateMoment);
    const secs = diffms / 1000;
    const mins = secs / 60;
    const hours = mins / 60;
    const days = hours / 24;
    const weeks = days / 7;
    const years = weeks / 52;

    if (mins < 1) {
      result.count = Math.floor(secs);
      // fix bug where timestamp is sporadically -1 (JE)
      if (result.count < 0) result.count = 0;
      result.unit = 's';
    } else if (hours < 1) {
      result.count = Math.floor(mins);
      result.unit = 'm';
    } else if (days < 1) {
      result.count = Math.floor(hours);
      result.unit = 'h';
    } else if (weeks < 1) {
      result.count = Math.floor(days);
      result.unit = 'd';
    } else if (years < 1) {
      result.count = Math.floor(weeks);
      result.unit = 'w';
    } else {
      result.count = Math.floor(years);
      result.unit = 'y';
    }

    return result;
  }

  // returns a span of variable unit (day, week, year)
  // including the amount and the unit (JE)
  getMomentSinceDayOrGreater(date: string) {
    const result = { count: 0, unit: '' };
    const now = this.moment();
    const dateMoment = this.moment(date);
    const diffms = now.diff(dateMoment);
    const secs = diffms / 1000;
    const mins = secs / 60;
    const hours = mins / 60;
    const days = hours / 24;
    const weeks = days / 7;
    const years = weeks / 52;

    if (days < 1) {
      result.unit = 'Today';
    } else if (weeks < 1) {
      result.count = Math.floor(days);
      result.unit = 'd';
    } else if (years < 1) {
      result.count = Math.floor(weeks);
      result.unit = 'w';
    } else {
      result.count = Math.floor(years);
      result.unit = 'y';
    }
    return result;
  }

  getFormattedMomentFromDate(date: string, format: string): string {
    return this.moment(date).format(format);
  }

  getFormattedMoment(date: string, format: string): string {
    return this.moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format(format);
  }

  getFormattedFromISO(ISOstring: string, format: string): string {
    return this.moment(ISOstring).format(format);
  }

  getFormattedNow(format: string): string {
    return this.moment().format(format);
  }

  getMonthDayYear() {
    const forwardSlash = /\//gi;
    return this.moment()
      .format('L')
      .replace(forwardSlash, '-');
  }

  getStageOfDayString (): string {
    let timeOfDay = 'day';
    const hours = this.getFormattedNow('HH');
    const hourOfDay = parseInt(hours);
    if (hourOfDay < 12) timeOfDay = 'morning';
    else if (hourOfDay >= 12 && hourOfDay < 17) timeOfDay = 'afternoon';
    else if (hourOfDay >= 17) timeOfDay = 'evening';
    return timeOfDay;
  }
}
