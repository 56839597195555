export const CalendarGreenCheck = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 26.666h46.5" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M50.333 11.166H14.167A5.167 5.167 0 0 0 9 16.334V52.5a5.167 5.167 0 0 0 5.167 5.166h36.166A5.167 5.167 0 0 0 55.5 52.5V16.333a5.167 5.167 0 0 0-5.167-5.166z" stroke="#71717A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.916 6v10.333M42.584 6v10.333" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M32.333 48.667a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667z" fill="#16A34A"/><path d="m29 40 2 2 4-4" stroke="#fff" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const CalendarGrey = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 26.666h46.5" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M50.333 11.166H14.167A5.167 5.167 0 0 0 9 16.334V52.5a5.167 5.167 0 0 0 5.167 5.166h36.166A5.167 5.167 0 0 0 55.5 52.5V16.333a5.167 5.167 0 0 0-5.167-5.166z" stroke="#71717A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.916 6v10.333M42.584 6v10.333" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const CalendarWarningRed = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 26.666h46.5" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M50.333 11.166H14.167A5.167 5.167 0 0 0 9 16.334V52.5a5.167 5.167 0 0 0 5.167 5.166h36.166A5.167 5.167 0 0 0 55.5 52.5V16.333a5.167 5.167 0 0 0-5.167-5.166z" stroke="#71717A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.916 6v10.333M42.584 6v10.333" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M32.333 48.667a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667z" fill="#DC2626"/><path d="M32.333 37v3.333M32.333 43.667h.009" stroke="#fff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const CalendarWarningYellow = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 26.666h46.5" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M50.333 11.166H14.167A5.167 5.167 0 0 0 9 16.334V52.5a5.167 5.167 0 0 0 5.167 5.166h36.166A5.167 5.167 0 0 0 55.5 52.5V16.333a5.167 5.167 0 0 0-5.167-5.166z" stroke="#71717A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.916 6v10.333M42.584 6v10.333" stroke="#A1A1AA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M32.333 48.667a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667z" fill="#F2C436"/><path d="M32.333 37v3.333M32.333 43.667h.009" stroke="#666" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalGearDefault = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.44 4h-.88a4 4 0 0 0-4 4v.36a4 4 0 0 1-2 3.46l-.86.5a4 4 0 0 1-4 0l-.3-.16a4 4 0 0 0-5.46 1.46l-.44.76a4 4 0 0 0 1.46 5.46l.3.2a4 4 0 0 1 2 3.44v1.02a4 4 0 0 1-2 3.48l-.3.18a4 4 0 0 0-1.46 5.46l.44.76a4 4 0 0 0 5.46 1.46l.3-.16a4 4 0 0 1 4 0l.86.5a4 4 0 0 1 2 3.46V40a4 4 0 0 0 4 4h.88a4 4 0 0 0 4-4v-.36a4 4 0 0 1 2-3.46l.86-.5a4 4 0 0 1 4 0l.3.16a4 4 0 0 0 5.46-1.46l.44-.78a4 4 0 0 0-1.46-5.46l-.3-.16a4 4 0 0 1-2-3.48v-1a4 4 0 0 1 2-3.48l.3-.18a4 4 0 0 0 1.46-5.46l-.44-.76a4 4 0 0 0-5.46-1.46l-.3.16a4 4 0 0 1-4 0l-.86-.5a4 4 0 0 1-2-3.46V8a4 4 0 0 0-4-4z" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 30a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalGearSelected = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.44 4h-.88a4 4 0 0 0-4 4v.36a4 4 0 0 1-2 3.46l-.86.5a4 4 0 0 1-4 0l-.3-.16a4 4 0 0 0-5.46 1.46l-.44.76a4 4 0 0 0 1.46 5.46l.3.2a4 4 0 0 1 2 3.44v1.02a4 4 0 0 1-2 3.48l-.3.18a4 4 0 0 0-1.46 5.46l.44.76a4 4 0 0 0 5.46 1.46l.3-.16a4 4 0 0 1 4 0l.86.5a4 4 0 0 1 2 3.46V40a4 4 0 0 0 4 4h.88a4 4 0 0 0 4-4v-.36a4 4 0 0 1 2-3.46l.86-.5a4 4 0 0 1 4 0l.3.16a4 4 0 0 0 5.46-1.46l.44-.78a4 4 0 0 0-1.46-5.46l-.3-.16a4 4 0 0 1-2-3.48v-1a4 4 0 0 1 2-3.48l.3-.18a4 4 0 0 0 1.46-5.46l-.44-.76a4 4 0 0 0-5.46-1.46l-.3.16a4 4 0 0 1-4 0l-.86-.5a4 4 0 0 1-2-3.46V8a4 4 0 0 0-4-4z" fill="#fff" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 30a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" fill="#2E41A8" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalHomeDefault = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 18 24 4l18 14v22a4 4 0 0 1-4 4H10a4 4 0 0 1-4-4V18z" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M18 44V24h12v20" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalHomeSelected = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 18 24 4l18 14v22a4 4 0 0 1-4 4H10a4 4 0 0 1-4-4V18z" fill="#fff" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M18 44V24h12v20" fill="#2E41A8"/><path d="M18 44V24h12v20" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalLogoutDefault = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 42h-8a4 4 0 0 1-4-4V10a4 4 0 0 1 4-4h8M32 34l10-10-10-10M42 24H18" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalMessageDefault = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 40A18 18 0 1 0 8 32.2L4 44l11.8-4zM16 24h.02M24 24h.02M32 24h.02" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalMessageSelected = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 40A18 18 0 1 0 8 32.2L4 44l11.8-4z" fill="#fff" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 24h.02z" fill="#2E41A8"/><path d="M16 24h.02" stroke="#2E41A8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 24h.02z" fill="#2E41A8"/><path d="M24 24h.02" stroke="#2E41A8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M32 24h.02z" fill="#2E41A8"/><path d="M32 24h.02" stroke="#2E41A8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const FamilyPortalNote = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#novhk3i8ya)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5.333 16H16M5.333 26.667H16M5.333 37.333H16M5.333 48H16M48 5.333H16a5.333 5.333 0 0 0-5.333 5.334v42.666A5.333 5.333 0 0 0 16 58.667h32a5.333 5.333 0 0 0 5.333-5.334V10.667A5.333 5.333 0 0 0 48 5.333z" stroke="#71717A"/><path d="M25.333 21.333h13.334M25.333 32h17.334M25.333 42.667h12" stroke="#A1A1AA"/></g><defs><clipPath id="novhk3i8ya"><path fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs></svg>';
export const FamilyPortalTransportation = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39 38v9M49.5 38v9" stroke="#A1A1AA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M30 47h29.4M54 56h4.5s.75-2.55 1.2-4.2c.15-.6.3-1.2.3-1.8 0-.6-.15-1.2-.3-1.8l-2.1-7.5c-.45-1.5-1.95-2.7-3.6-2.7H33a3 3 0 0 0-3 3v15h4.5" stroke="#71717A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M37.5 59a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM40.5 56H48M51 59a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM5 36V20a16 16 0 1 1 32 0v16" stroke="#71717A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M25.8 11.68v2.24c0 1.188-.506 2.328-1.406 3.168-.9.84-2.12 1.312-3.394 1.312-1.273 0-2.494-.472-3.394-1.312-.9-.84-1.406-1.98-1.406-3.168v-2.24M17.8 24.8h.019M24.2 24.8h.019" stroke="#A1A1AA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.8 31.2a6.4 6.4 0 0 1-6.4-6.4V20a9.6 9.6 0 0 1 19.2 0v4.8a6.4 6.4 0 0 1-6.4 6.4h-6.4zM16.2 31.2 13 36M25.8 31.2 29 36" stroke="#71717A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export const PersonHeartBlack = '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.333 14.667a5.333 5.333 0 1 0 0-10.667 5.333 5.333 0 0 0 0 10.667zM13.733 20h-4.4A5.333 5.333 0 0 0 4 25.333V28M25.267 24.889c.695-.649 1.4-1.427 1.4-2.445 0-.648-.27-1.27-.752-1.728A2.633 2.633 0 0 0 24.1 20c-.821 0-1.4.222-2.1.889-.7-.667-1.279-.889-2.1-.889-.68 0-1.333.258-1.815.716a2.387 2.387 0 0 0-.752 1.728c0 1.023.7 1.8 1.4 2.445L22 28l3.267-3.111z" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
