<div class="family-header">
  <div class="left-side">
    <div class="logo-container">
      <img
        class="header-logo"
        tabindex="0"
        [src]="headerImage.src"
        [alt]="headerImage.alt"
        (click)="goToNycWebsite()"
        (keydown.enter)="goToNycWebsite()"
      />
    </div>
    <span class="application-name">{{ applicationName }}</span>
  </div>
  <div class="right-side">
    <ng-container *ngTemplateOutlet="languageSwitcher"></ng-container>
    <div class="header-link" *ngIf="profileLabel && !hideProfileBtn">
      <div
        class="button"
        role="button"
        [tabindex]="1"
        (click)="goToProfile()"
        (keydown.enter)="goToProfile()"
      >{{ profileLabel }}
      </div>
    </div>
    <div class="header-link" *ngIf="logOutLabel && !hideLogoutBtn">
      <div
        class="button"
        role="button"
        [tabindex]="2"
        (click)="logOut()"
        (keydown.enter)="logOut()"
      >{{ logOutLabel }}
      </div>
    </div>
  </div>
</div>
