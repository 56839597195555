import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvAriaLabelComponent } from './nv-aria-label.component';
import { NvAriaLabelDirective } from './nv-arial-label.directive';
import { SharedUtilitiesService } from '../../../../../shared/services/utilities-service/utilities.service';
import { DateHelpers } from '../../../../services/date-helpers/date-helpers.service';

@NgModule({
  declarations: [NvAriaLabelComponent, NvAriaLabelDirective],
  providers: [SharedUtilitiesService, DateHelpers],
  imports: [CommonModule],
  exports: [NvAriaLabelComponent, NvAriaLabelDirective],
})
export class NvAriaLabelModule {}
