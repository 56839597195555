import { ComponentRef, Directive, ElementRef, Input, SimpleChanges, ViewContainerRef } from '@angular/core';
import { NvAriaLabelComponent } from './nv-aria-label.component';
import { SharedUtilitiesService } from '../../../../../shared/services/utilities-service/utilities.service';

@Directive({
  selector: '[nvAriaLabel]',
})
export class NvAriaLabelDirective {
  @Input() ariaLabelText?: string = null;
  @Input() ariaTargetElement?: string = null;

  constructor (
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private utils: SharedUtilitiesService,
  ) {}

  private componentRef: ComponentRef<NvAriaLabelComponent>;

  ngOnInit (): void {
    const host: HTMLElement = this.element.nativeElement;
    const target: HTMLElement = this.ariaTargetElement ? host.querySelector(this.ariaTargetElement) : host;

    if (!this.ariaLabelText) {
      this.setAttribute(target, 'aria-hidden', !this.ariaLabelText);
      return;
    }

    if (!target) {
      return;
    }

    // 1. create unique aria label id
    const ariaLabelId = this.utils.createV4Uuid();

    // 2. set aria-labelledby attribute with the unique id
    this.setAttribute(target, 'aria-labelledby', ariaLabelId);

    // 3. create and insert the aria label component
    const compToInsert = this.viewContainerRef.createComponent(NvAriaLabelComponent);
    compToInsert.instance.ariaLabelText = this.ariaLabelText;
    compToInsert.instance.ariaLabelId = ariaLabelId;
    this.componentRef = compToInsert;
    this.insertAfter(target, compToInsert.location.nativeElement);
  }

  ngOnChanges (changes: SimpleChanges) {
    if (!changes.firstChange && changes.ariaLabelText && this.componentRef) {
      this.componentRef.instance.ariaLabelText = changes.ariaLabelText.currentValue;
    }
  }

  private insertAfter (referenceNode, newNode): void {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  private setAttribute (node, attr, value): void {
    node.setAttribute(attr, value);
  }
}
