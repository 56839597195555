import { Route } from '@angular/router';
import { authGuard } from '../../guards/auth-guard/auth-guard';
import { FamilyPortalLayoutPageComponent } from 'src/app/components/family-portal-layout-page/family-portal-layout-page.component';
import { HomeGuard } from '../../guards/home-guard/home-guard';

export const messagesRoute: Route = {
  path: 'messages',
  component: FamilyPortalLayoutPageComponent,
  loadChildren: () => import('../../../components/messages/messages.module').then(m => m.MessagesModule),
  canActivate: [authGuard, HomeGuard],
};
