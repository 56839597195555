<ng-container *ngIf="!option.options">
  <button
    mat-menu-item
    (click)="clickedOption(option.key)"
    [id]="option.key"
    [disabled]="option.disabled"
    [ngClass]="btnClass"
  >
    <span class="dropdown-item-label {{option.customClass}}">{{ option.human }}</span>
  </button>
</ng-container>
<ng-container *ngIf="!!option.options">
  <button
    mat-menu-item
    [matMenuTriggerFor]="childMenu"
    [id]="option.key"
    [disabled]="option.disabled"
    [ngClass]="btnClass"
  >
    <span class="dropdown-item-label">{{ option.human }}</span>
    <nv-icon name="arrow-right-default"></nv-icon>
  </button>
  <mat-menu #childMenu="matMenu" id="{{ option.key }}-menu">
    <ng-template matMenuContent>
      <nv-dropdown-item
        *ngFor="let suboption of option.options"
        (selectItem)="selectedSuboption($event)"
        [option]="suboption"
        [selected]="selected"
      ></nv-dropdown-item>
    </ng-template>
  </mat-menu>
</ng-container>
