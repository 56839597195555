import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvLogoComponent } from './nv-logo.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NvLogoComponent],
  exports: [NvLogoComponent],
})
export class NvLogoModule {}
