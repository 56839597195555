<ng-container [ngSwitch]="navType">
  <div class="side-nav" *ngSwitchCase="'SIDE_NAV'">
    <div class="logo-container">
      <nv-logo
        (click)="navigateToHomepage()"
        [pointerOnHover]="true"
        type="family-portal-logo-symbol-white-no-text"
        nvAriaLabel
        [ariaLabelText]="content.ecfikLogoAltText"
        ariaTargetElement="svg"
      ></nv-logo>
    </div>
    <shared-sidebar-list-responsive
      [listData]="navConfigs.main"
      [selectedKey]="selectedSidebarItem"
      (sidebarListItemSelect)="onNavItemSelect(navConfigs, { selectedItemKey: $event })"
      [styleType]="'SIDE'"
    ></shared-sidebar-list-responsive>
  </div>

  <div class="top-nav-closed top-nav-controls" *ngSwitchCase="'TOP_NAV_CLOSED'">
    <div class="logo-container">
      <nv-logo 
        (click)="navigateToHomepage()"
        [pointerOnHover]="true"
        type="family-portal-logo-symbol-white-no-text"
        nvAriaLabel
        [ariaLabelText]="content.ecfikLogoAltText"
        ariaTargetElement="svg"
      ></nv-logo>
    </div>
    <nv-icon-button
      isInverted="true"
      name="hamburger-large-white"
      [ariaLabelData]="{ 'iconButtonAriaLabel': content.openMenu }"
      (clickButton)="onClickBurgerMenu({ action: 'openTopNavMenu' })"
    ></nv-icon-button>
  </div>

  <div class="top-nav-opened" *ngSwitchCase="'TOP_NAV_OPENED'">
    <div class="top-nav-controls">
      <div class="logo-container">
        <nv-logo 
        type="family-portal-logo-symbol-white-no-text"
        nvAriaLabel
        [ariaLabelText]="content.ecfikLogoAltText"
        ariaTargetElement="svg"
      ></nv-logo>
      </div>
      <nv-icon-button
        isInverted="true"
        name="close-large-white"
        [ariaLabelData]="{ iconButtonAriaLabel: content.closeMenu }"
        (clickButton)="onClickBurgerMenu({ action: 'closeTopNavMenu' })"
      >
      </nv-icon-button>
    </div>
    <shared-sidebar-list-responsive
      [listData]="navConfigs.main"
      [selectedKey]="selectedSidebarItem"
      (sidebarListItemSelect)="onNavItemSelect(navConfigs, { selectedItemKey: $event })"
      [styleType]="'FULL_SCREEN'"
    ></shared-sidebar-list-responsive>
    <div class="nav-footer"><ng-content select="[navFooter]"></ng-content></div>
  </div>
</ng-container>
