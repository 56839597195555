import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as portalV3icons from '../assets/icons';
import * as portalV4Icons from '../assets/portal-v4-icons';
import * as familyPortalIcons from '../../../../family-portal/src/assets/family-portal-icons';
import { toKebabFromPascal } from '../utilities/helpers';

@Injectable()
export class NvIconService {
  private _icons: { [key: string]: string } = {};

  constructor (private _registryService: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._icons = { ...portalV3icons, ...portalV4Icons, ...familyPortalIcons };
  }

  registerAllIcons () {
    Object.entries(this._icons).forEach(icon => {
      this.registerIcon(icon[0], icon[1]);
    });
  }

  registerIcon (name: string, svg: string) {
    const svgElement = this._domSanitizer.bypassSecurityTrustHtml(svg);
    const iconName = toKebabFromPascal(name);
    this._registryService.addSvgIconLiteral(iconName, svgElement);
  }
}
