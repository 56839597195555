import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { take, tap } from 'rxjs/operators';

/**
 *
 * The icon component renders an icon `svg` that matches the provided `name` input.
 *
 * Icons have an inherent height and width of `24px | 16px`. To resize icons reset the height & width in your parent CSS.
 *
 *
 */
@Component({
  selector: 'nv-icon',
  templateUrl: './nv-icon.component.html',
  styleUrls: ['./nv-icon.component.scss'],
  // ShadowDom is safer, but this causes issues in style injection
  encapsulation: ViewEncapsulation.None,
})
export class NvIconComponent implements OnChanges, OnInit {
  get rootElement () {
    // add .shadowRoot if using ViewEncapsulation.ShadowDom
    return this.elementRef.nativeElement; // .shadowRoot;
  }

  /**
   *
   *
   */
  @Input() name: string;

  /**
   *
   * Note: Does not apply to increase, decrease or neutral icons
   */
  @Input() color: string = 'blue';

  /**
   *
   *
   */
  @Input() isInverted: boolean = false;

  @Input() addHover: boolean = false;

  svgElementRef: SVGElement;

  constructor (public elementRef: ElementRef<HTMLElement>, public iconRegistry: MatIconRegistry) {}

  ngOnInit () {
    this.setIcon(this.name, this.addHover);
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.name && this.name) {
      this.setIcon(this.name, this.addHover);
    } else if (changes.color && this.color) {
      this.setColorClass(changes.color.previousValue, changes.color.currentValue);
    } else if (changes.isInverted) {
      this.setInvertedClass(this.isInverted);
    }
  }

  setIcon (name, addHover) {
    this.iconRegistry
      .getNamedSvgIcon(name)
      .pipe(
        take(1),
        tap(svg => this.setSvgElement(svg, name, addHover)),
      )
      .subscribe(
        null,
        (err: Error) => {
          console.error(`Error retrieving icon: ${err.message}`);
        },
        null,
      );
  }

  setSvgElement (svg: SVGElement, iconName?: string, addHover?: boolean) {
    this.clearSvgElement();
    this.rootElement.appendChild(svg);
    this.svgElementRef = this.rootElement.querySelector('svg');
    this.setColorClass(null, this.color);
    this.setInvertedClass(this.isInverted);
    this.setSizeClass(iconName);
    this.setHoverClass(addHover);
  }

  clearSvgElement () {
    let childCount = this.rootElement.childNodes.length;

    // Remove existing non-element child nodes and SVGs, and add the new SVG element
    while (childCount--) {
      const child = this.rootElement.childNodes[childCount];
      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        this.rootElement.removeChild(child);
      }
    }
  }

  setColorClass (prevColor: string, newColor: string) {
    if (prevColor) {
      this.svgElementRef.classList.remove(prevColor.toLowerCase());
    }
    this.svgElementRef.classList.add(newColor.toLowerCase());
  }

  setInvertedClass (isInverted: boolean) {
    if (isInverted) {
      this.svgElementRef.classList.add('inverted');
    } else {
      this.svgElementRef.classList.remove('inverted');
    }
  }

  setSizeClass (iconName: string): void {
    if (iconName?.includes('small')) {
      // small is 16px
      this.rootElement.classList.add('small');
    } else {
      this.rootElement.classList.remove('small');
    }
  }

  setHoverClass (addHover: boolean): void {
    if (addHover) {
      this.svgElementRef.classList.add('elevate-shadow');
    } else {
      this.svgElementRef.classList.remove('elevate-shadow');
    }
  }
}
