import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvDropdownItemComponent } from './nv-dropdown-item.component';

@NgModule({
  imports: [CommonModule, MatRippleModule, MatMenuModule, NvIconModule],
  declarations: [NvDropdownItemComponent],
  exports: [NvDropdownItemComponent],
})
export class NvDropdownItemModule {}
