import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { NavBarComponent } from './nav-bar.component';
import { NvIconModule } from '../../nvps-libraries/design/nv-icon/nv-icon.module';
import { NvIconButtonModule } from '../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { SharedSidebarListResponsiveModule } from './shared-sidebar-list-responsive/shared-sidebar-list-responsive.module';
import { NvLogoModule } from '../../nvps-libraries/design/nv-logo/nv-logo.module';
import { NavbarConfigService } from './nav-bar.config.service';
import { NvAriaLabelModule } from '../../nvps-libraries/design/directives/nv-aria-label/nv-aria-label.module';

@NgModule({
  declarations: [NavBarComponent],
  imports: [CommonModule, NvIconModule, NvIconButtonModule, SharedSidebarListResponsiveModule, NvLogoModule, NvAriaLabelModule],
  exports: [NavBarComponent],
  providers: [NavbarConfigService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NavBarModule {}
