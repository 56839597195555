import { FamilyPortalLayoutPageComponent } from 'src/app/components/family-portal-layout-page/family-portal-layout-page.component';
import { Route } from '@angular/router';

export const unauthorizedRoute: Route = {
  path: 'not-found',
  component: FamilyPortalLayoutPageComponent,
  data: {
    hideSideNav: true,
    hideLogoutBtn: true,
    hideProfileBtn: true,
  },
  loadChildren: () => import('../../../components/not-found/not-found.module').then(m => m.NotFoundModule),
};
