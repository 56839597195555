
import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

/**
 *
 * A basic button component.
 *
 * Use `primary` buttons for the primary action on a page such as saving or submitting.
 *
 * Use `secondary` buttons for other actions, such as canceling an action.
 *
 * Small buttons should be used when a large button is not appropriate for the location. Small buttons also support icons.
 *
 */

@Component({
  selector: 'nv-button',
  templateUrl: './nv-button.component.html',
  styleUrls: ['./nv-button.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvButtonComponent {
  /**
   * Defines the color of the button
   * - primary - color: --color-white | background-color: --color-blue
   * - secondary - color: --color-blue | background-color: --color-white
   * - tertiary - color: --color-blue | background-color: --color-blue-10
   * - filter - color: --color-purple-dark-1 | background-color: --color-purple-light-1
   */
  @Input() type: 'primary' | 'secondary' | 'tertiary' | 'filter' | 'note' = 'primary';

  /**
   * Determines if the button is clickable
   */
  @Input() disabled: boolean = false;

  /**
   *
   * Size of the button
   */
  @Input() size: 'normal' | 'small' | 'xsmall' = 'normal';

  /**
   *
   * The name of the icon to display
   */
  @Input() icon: string;

  /**
   *
   * Determines if the icon to display appears before or after the content
   */
  @Input() iconLocation: 'before' | 'after' = 'before';

  /**
   *
   * The HTML `type` attribute to apply to the button.
   */
  @Input() htmlType: 'submit' | 'reset' | 'button' = 'button';

  /**
   *
   * Determines if the button has an active state
  *  currently only used by the filter type button
   */
  @Input() isActive: boolean = false;

  /**
  *
  * The visually hidden ARIA label for describing beforeIcon and afterIcon purpose.
  */
  @Input() ariaLabelData?: {
    beforeIconAriaLabel?: string;
    afterIconAriaLabel?: string;
  } = {
      beforeIconAriaLabel: null,
      afterIconAriaLabel: null,
    };

  /**
   *
   *
   */
  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.small') get isSmall () {
    return this.size === 'small';
  }

  @HostBinding('class.xsmall') get isXSmall () {
    return this.size === 'xsmall';
  }

  @HostBinding('class.primary') get isPrimary () {
    return this.type === 'primary';
  }

  @HostBinding('class.secondary') get isSecondary () {
    return this.type === 'secondary';
  }

  @HostBinding('class.tertiary') get isTertiary () {
    return this.type === 'tertiary';
  }

  @HostBinding('class.filter') get isFilter () {
    return this.type === 'filter';
  }

  @HostBinding('class.note') get isNote () {
    return this.type === 'note';
  }

  @HostBinding('class.is-disabled') get isDisabled () {
    return this.disabled;
  }

  @HostBinding('class.hasIconBefore') get hasIcon () {
    return !!this.icon && this.iconLocation === 'before';
  }

  @HostBinding('class.hasIconAfter') get hasIconAfter () {
    return !!this.icon && this.iconLocation === 'after';
  }

  @HostBinding('class.active') get isActiveClass () {
    return this.isActive;
  }

  constructor () {}

  public get shouldShowIcon (): boolean {
    return !!this.icon;
  }

  public get isIconInverted (): boolean {
    return !!(this.type === 'primary');
  }

  handleClick (event?: Event) {
    if (this.disabled) {
      event.stopPropagation();
    } else {
      this.clickButton.emit(true); // Emit the `press` event
    }
  }

  onMouseDown(event?: Event){
    if (!this.disabled) { 
      event.preventDefault();
    }
  }
}
