import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavbarConfigService } from './nav-bar.config.service';
import { ISharedSidebarItem } from './shared-sidebar-list/shared-sidebar.interface';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { flattenDeep, valuesIn } from 'lodash';
import { INavBarAccessibilityContent } from './nav-bar.interface';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavBarComponent implements OnInit {
  public content: INavBarAccessibilityContent = {
    openMenu: 'Open menu',
    closeMenu: 'Close menu',
    ecfikLogoAltText: 'Every Child and Family is Known',
  };

  public selectedSidebarItem: string;
  public navConfigs: { [key:string]: ISharedSidebarItem[] } = {
    main: [],
    small: [],
  };
  private activeUrl: string | undefined;
  private destroy$ = new Subject<void>();
  @Input() navType: 'SIDE_NAV' | 'TOP_NAV_CLOSED' | 'TOP_NAV_OPENED' = 'SIDE_NAV';
  @Input() translatedNavBarConfig$?: Observable<{ [key:string]: ISharedSidebarItem[] }>;
  @Input() translatedNavBarAccessibility$?: Observable<INavBarAccessibilityContent>;
  @Output() clickedBurgerMenu = new EventEmitter<{ action: 'closeTopNavMenu' | 'openTopNavMenu' }>();

  constructor (
    private auth: AuthService,
    private router: Router,
    private navbarConfigService: NavbarConfigService,
  ) {}

  ngOnInit (): void {
    this.setNavConfigs();
    this.translatedNavBarAccessibility$?.pipe(
      tap((content) => {
        this.content = content;
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy (): void {
    this.destroy$.next();
  }

  // TODO - further discuss if we could generalize and use AuthService here
  public logout (): void {
    this.auth.logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }

  public navigateToHomepage (): void {
    this.router.navigate(['/']);
  }

  public onClickBurgerMenu ({ action }: { action: 'closeTopNavMenu' | 'openTopNavMenu'}): void {
    this.clickedBurgerMenu.emit({ action });
  }

  public onNavItemSelect (navConfigs: { [key:string]: ISharedSidebarItem[] }, { selectedItemKey, activeUrl }: { selectedItemKey?: string, activeUrl?: string }): void {
    const navItem = this.updateSelectedItem(navConfigs, { selectedItemKey, activeUrl });
    const keyToCompare = navItem?.key.toLowerCase();
    if (keyToCompare === 'log out') {
      this.logout();
    }
  }

  public updateSelectedItem (navConfigs: { [key:string]: ISharedSidebarItem[] }, { selectedItemKey, activeUrl }: { selectedItemKey?: string, activeUrl?: string }): ISharedSidebarItem | undefined | null {
    const all = flattenDeep(valuesIn(navConfigs));
    const navItem = all.find(({ key, url }) => key === selectedItemKey || activeUrl?.includes(`${this.getActiveUrl(url)}`));
    if (navItem) {
      this.selectedSidebarItem = navItem.key;
      return navItem;
    }
  }

  private getActiveUrl (url: string | undefined): string | undefined {
    if (!url) return;
    const [urlWithoutParams] = url.split('?');
    return urlWithoutParams;
  }

  private setNavConfigs () {
    if (this.translatedNavBarConfig$) {
      this.translatedNavBarConfig$.pipe(
        tap((navConfigs) => {
          this.navConfigs = navConfigs;
          this.activeUrl = this.getActiveUrl(this.router.routerState.snapshot.url);
          this.onNavItemSelect(this.navConfigs, { activeUrl: this.activeUrl });
        }),
        takeUntil(this.destroy$),
      ).subscribe();
    } else {
      this.navConfigs = this.navbarConfigService.getNavConfigs();
      this.activeUrl = this.getActiveUrl(this.router.routerState.snapshot.url);
      this.onNavItemSelect(this.navConfigs, { activeUrl: this.activeUrl });
    }
  }
}
