import { ActivatedRoute } from '@angular/router';
import { FamilyPortalMixpanelService } from './../../services/mixpanel/family-portal-mixpanel.service';
import { FamilyEventFormatterService } from 'src/app/services/mixpanel/family-event-formatter.service';
import { Component, Inject, Renderer2, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { FamilyPortalConfig } from 'src/app/services/family-portal-config';
import moment from 'moment';
import { WindowRef } from '../../../../../shared/services/window-ref/windowRef';
import { BehaviorSubject, Subject } from 'rxjs';
import { ILinkAccessibilityContent } from '../../../../../shared/components/skip-link/skip-link.interface';
import { INavBarAccessibilityContent, INavBarConfigContent } from '../../../../../shared/layouts/responsive-nav-bar/nav-bar.interface';
import { SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_TO_ENGLISH_MAP, TLang } from '../../../../../shared/constants/supported-languages';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '../../../app/services/translate/translate.service';
import { takeUntil, tap, take } from 'rxjs/operators';
import { defaultNavBarContent } from '../../constants/nav-bar.config';
import { NavbarConfigService } from '../../../../../shared/layouts/responsive-nav-bar/nav-bar.config.service';
import { ISharedSidebarItem } from '../../../../../shared/layouts/responsive-nav-bar/shared-sidebar-list/shared-sidebar.interface';
import { ICurrentUser } from 'src/app/interfaces/current-user.interface';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import { FAMILY_EVENT_TYPES } from '../../../../../shared/typings/interfaces/family-mixpanel.interface';
import { FAMILY_PORTAL_TYPES } from '../../../../../shared/typings/interfaces/portal.interface';
// TODO: school-portal import. Remove this line and import from the correct path when available
// eslint-disable-next-line import/no-restricted-paths
import { LocalStorageService } from './../../../../../../src/ng2/shared/services/web-storage/local-storage/local-storage.service';

@Component({
  selector: 'family-portal-layout-page',
  templateUrl: './family-portal-layout-page.component.html',
  styleUrls: ['./family-portal-layout-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FamilyPortalLayoutPageComponent {
  public headerProps = {
    applicationName: 'Family Portal: Every Child and Family is Known',
    logOutLabel: 'Log out',
    profileLabel: 'Profile',
    profileUrl: this.familyPortalConfig.publicConfig.NYC_ID_PROFILE_URL,
    headerImage: {
      src: 'https://www.nyc.gov/assets/nyc4d/images/pages/intergration/nyc_white@x2.png',
      alt: 'white NYC logo',
    },
  };

  public langSwitcherProps: {
    selectedLang: string;
    languages: { key: string; human: string }[];
  };

  public translatedLinkAccessibility$: BehaviorSubject<ILinkAccessibilityContent> = new BehaviorSubject<ILinkAccessibilityContent>(null);
  public translatedNavBarAccessibility$: BehaviorSubject<INavBarAccessibilityContent> = new BehaviorSubject<INavBarAccessibilityContent>(null);
  public translatedNavBarConfig$: BehaviorSubject<{ [key:string]: ISharedSidebarItem[] }> = new BehaviorSubject<{ [key:string]: ISharedSidebarItem[] }>(null);

  private destroy$: Subject<void> = new Subject<void>();

  public hideNav: boolean = false;
  public hideLogoutBtn: boolean = false;
  public hideProfileBtn: boolean = false;

  constructor (
    private authService: AuthService,
    private familyPortalConfig: FamilyPortalConfig,
    private windowRef: WindowRef,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private navbarConfigService: NavbarConfigService,
    private localStorageService: LocalStorageService,
    private familyEventFormatterService: FamilyEventFormatterService,
    private familyPortalMixpanelService: FamilyPortalMixpanelService,
    private currentUserService: CurrentUserService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.setMixpanelUser();
    const lang = this.translateService.getActiveLang();
    this.setHtmlLangAttribute(lang);
    this.langSwitcherProps = {
      selectedLang: lang,
      languages: SUPPORTED_LANGUAGES,
    };

    this.hideNav = this.activatedRoute.snapshot.data.hideSideNav;
    this.hideLogoutBtn = this.activatedRoute.snapshot.data.hideLogoutBtn;
    this.hideProfileBtn = this.activatedRoute.snapshot.data.hideProfileBtn;

    // Translations for child components
    const linkAccessibilityContent : ILinkAccessibilityContent = {
      skipToMainContent: 'Skip to main content',
      skipToFooter: 'Skip to footer',
    };

    const navBarAccessibilityContent: INavBarAccessibilityContent = {
      openMenu: 'Open menu',
      closeMenu: 'Close menu',
      ecfikLogoAltText: 'Every Child and Family is Known',
    };

    const navBarConfigContent: INavBarConfigContent = {
      home: defaultNavBarContent.home,
      messages: defaultNavBarContent.messages,
      myAccount: defaultNavBarContent.myAccount,
    };

    this.translateService.translateText([
      linkAccessibilityContent.skipToMainContent,
      linkAccessibilityContent.skipToFooter,
      navBarAccessibilityContent.openMenu,
      navBarAccessibilityContent.closeMenu,
      navBarAccessibilityContent.ecfikLogoAltText,
      navBarConfigContent.home,
      navBarConfigContent.messages,
      navBarConfigContent.myAccount,
    ]).pipe(
      tap((translated) => {
        const [
          skipToMainContent,
          skipToFooter,
          openMenu,
          closeMenu,
          ecfikLogoAltText,
          home,
          messages,
          myAccount,
        ] = translated;
        this.translatedLinkAccessibility$.next({ skipToMainContent, skipToFooter });
        this.translatedNavBarAccessibility$.next({ openMenu, closeMenu, ecfikLogoAltText });
        const translatedNavBarConfig = this.navbarConfigService.getNavConfigs({ translatedContent: { home, messages, myAccount } });
        this.translatedNavBarConfig$.next(translatedNavBarConfig);
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy () {
    this.destroy$.next();
  }

  public logOut (): void {
    const window = this.windowRef.nativeWindow;

    this.trackLogout();
    this.authService.logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }

  public onSwitchLanguage (lang: string) {
    this.translateService.switchLanguage(lang);
    this.setHtmlLangAttribute(lang);
  };

  private setHtmlLangAttribute (lang: string) {
    this.renderer.setAttribute(this.document.documentElement, 'lang', lang);
  }

  public trackLanguageSwitchAction (key: TLang): void {
    const event = this.familyEventFormatterService.getLanguageSwitcherEvent({
      action: FAMILY_EVENT_TYPES.LANGUAGE_SELECT,
      key,
      language: SUPPORTED_LANGUAGES_TO_ENGLISH_MAP[key],
      portal: FAMILY_PORTAL_TYPES.FAMILY,
    });
    this.familyPortalMixpanelService.trackEvents([event]);
  }

  private setMixpanelUser (): void {
    this.currentUserService.getCurrentUser$().pipe(
      tap((currentUser: ICurrentUser) => {
        const { guardianId } = currentUser;
        const lastLogin = moment().toString();
        this.localStorageService.setItem('lastLogin', lastLogin);

        this.familyPortalMixpanelService.updateUser(guardianId, {
          'Last Login': lastLogin,
        });
      }),
      take(1),
    ).subscribe();
  }

  private trackLogout () {
    const lastLogin = moment(this.localStorageService.getItem('lastLogin'));
    const logoutTime = moment();
    const sessionTime = moment.utc(logoutTime.diff(lastLogin)).format('mm:ss.SSS');

    const event = this.familyEventFormatterService.getPageViewTimeEvent({
      sessionTime,
      page: 'OVERALL',
      portal: FAMILY_PORTAL_TYPES.FAMILY,
    });
    this.familyPortalMixpanelService.trackEvents([event]);
  }
}
