import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NvPillV4Component } from '../nv-pill-v4/nv-pill-v4.component';

@Component({
  selector: 'nv-pill-button-v4',
  templateUrl: './nv-pill-button-v4.component.html',
  styleUrls: ['./nv-pill-button-v4.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvPillButtonV4Component extends NvPillV4Component {
  @Input() htmlType: 'submit' | 'reset' | 'button' = 'button';

  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();

  public handleClick (_: Event): void {
    this.clickButton.emit(true); // Emit the `press` event
  }
}
