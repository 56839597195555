import { FamilyPortalLayoutPageComponent } from './../../../components/family-portal-layout-page/family-portal-layout-page.component';
import { Route } from '@angular/router';
import { authGuard } from '../../guards/auth-guard/auth-guard';

export const consentRoute: Route = {
  path: 'consent',
  component: FamilyPortalLayoutPageComponent,
  data: {
    hideSideNav: true,
    hideProfileBtn: true,
  },
  loadChildren: () => import('../../../components/consent-interstitial-page/consent-interstitial-page.module').then(m => m.ConsentInterstitialPageModule),
  canActivate: [authGuard],
  title: 'Consent',
};
