import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../interfaces/design-library.interface';
/* istanbul ignore next */

/**
 *
 * This is the dropdown item component used by `nv-dropdown`.
 *
 * Typically you won't need to use this component directly.
 * It's recommended to use the `nv-dropdown` if you need dropdown menu functionality.
 *
 * However there are times when you might need to trigger a menu from a different component (like a button or icon-button).
 *
 * IMPORTANT: this component does not support nested options. If you have options that may be nested, use `nv-dropdown-menu` instead
 *
 * To build a custom dropdown (i.e. using a `nv-button` as a trigger), attach a `mat-menu` to the trigger as documented in the
 * [Material Docs](https://material.angular.io/components/menu/overview), and use this component as the contents of `mat-menu`:
 *
 * Example:
 *
 * ```
 * <nv-button [matMenuTriggerFor]="dropdownMenu"></nv-button>
 *
 * <mat-menu #dropdownMenu="matMenu" panelClass="nv-mat-menu" ...>
 *   <nv-dropdown-item *ngFor="let option of options" [option]="option" ...>
 *   </nv-dropdown-item>
 * </mat-menu>
 * ```
 */
@Component({
  selector: 'nv-dropdown-item',
  templateUrl: './nv-dropdown-item.component.html',
  styleUrls: ['./nv-dropdown-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvDropdownItemComponent {
  /**
   * The key of the selected option. Used for styling the selected option and its parents
   */
  @Input() selected: string;

  /**
   *
   * The `IDropdownOption` for the menu item
   *
   * ```
   * interface IDropdownOption {
   *  key: string,
   *  human: string,
   *  disabled?: boolean,
   *  options?: Array<IDropdownOption>
   * }
   * ```
   */
  @Input() option: IDropdownOption;

  /**
   *
   * Emits the key of the selected `IDropdownOption`
   */
  @Output() selectItem: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class.isdisabled') get isDisabled () {
    return this.option.disabled === true;
  }

  constructor () {}

  selectedSuboption (optionKey: string) {
    this.selectItem.emit(optionKey);
  }

  clickedOption (optionKey: string) {
    this.selectItem.emit(optionKey);
  }

  isCurrentSelected (): boolean {
    return this.option.key === this.selected;
  }

  isParentOfSelection (option = this.option): boolean {
    // Are we a leaf?
    if (!option.options) {
      return option.key === this.selected;
    } else {
      // loop over each suboption
      for (const subopt of option.options) {
        // If we know we're a parent of the selection, then return
        if (this.isParentOfSelection(subopt)) {
          return true;
        }
      }
      return false;
    }
  }

  get btnClass () {
    const customClass = `${this.option.customClass}`;
    return this.isCurrentSelected() ? `${customClass} selection` : this.isParentOfSelection() ? `${customClass} parent-of-selection` : `${customClass}`;
  }
}
